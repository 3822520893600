import React from "react";
import { Badge, BadgeProps, makeStyles } from "@fluentui/react-components";

export enum PincitesBadgeColor {
  BRAND = "brand",
  DANGER = "danger",
  IMPORTANT = "important",
  INFORMATIVE = "informative",
  SEVERE = "severe",
  SUBTLE = "subtle",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum PincitesBadgeAppearance {
  FILLED = "filled",
  OUTLINE = "outline",
  GHOST = "ghost",
  TINT = "tint",
}

const useStyles = makeStyles({
  badge: {
    scale: "0.8",
  },
});

export default function PincitesBadge({
  text,
  appearance,
  color,
}: {
  text: string;
  appearance: PincitesBadgeAppearance;
  color: PincitesBadgeColor;
}): React.JSX.Element {
  const styles = useStyles();

  return (
    <Badge
      color={getColorFromPincitesBadgeColor(color)}
      appearance={getAppearanceFromPincitesBadgeAppearance(appearance)}
      className={styles.badge}
      id="pincites-badge"
    >
      {text}
    </Badge>
  );
}

function getAppearanceFromPincitesBadgeAppearance(
  badgeAppearance: PincitesBadgeAppearance,
): BadgeProps["appearance"] {
  switch (badgeAppearance) {
    case PincitesBadgeAppearance.FILLED:
      return "filled";
    case PincitesBadgeAppearance.OUTLINE:
      return "outline";
    case PincitesBadgeAppearance.GHOST:
      return "ghost";
    case PincitesBadgeAppearance.TINT:
      return "tint";
    default:
      return "filled";
  }
}

function getColorFromPincitesBadgeColor(
  badgeColor: PincitesBadgeColor,
): BadgeProps["color"] {
  switch (badgeColor) {
    case PincitesBadgeColor.BRAND:
      return "brand";
    case PincitesBadgeColor.DANGER:
      return "danger";
    case PincitesBadgeColor.IMPORTANT:
      return "important";
    case PincitesBadgeColor.INFORMATIVE:
      return "informative";
    case PincitesBadgeColor.SEVERE:
      return "severe";
    case PincitesBadgeColor.SUBTLE:
      return "subtle";
    case PincitesBadgeColor.SUCCESS:
      return "success";
    case PincitesBadgeColor.WARNING:
      return "warning";
    default:
      return "brand";
  }
}
