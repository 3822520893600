import React from "react";
import {
  Add16Filled,
  Add16Regular,
  ArrowDownloadFilled,
  ArrowDownloadRegular,
  ArrowLeft24Regular,
  ArrowSyncCheckmark24Regular,
  ArrowUndoFilled,
  ArrowUndoRegular,
  BookFilled,
  BookRegular,
  bundleIcon,
  Checkmark20Regular,
  CircleLineFilled,
  CircleLineRegular,
  CopySelectFilled,
  CopySelectRegular,
  DeleteFilled,
  DeleteRegular,
  Dismiss24Regular,
  EditFilled,
  EditRegular,
  ErrorCircle20Regular,
  HistoryFilled,
  HistoryRegular,
  LightbulbFilled,
  LightbulbRegular,
  MoreHorizontal20Regular,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
  OpenRegular,
  PersonFeedback20Filled,
  PersonFeedback20Regular,
  PersonFilled,
  PersonRegular,
  PlayCircleFilled,
  PlayCircleRegular,
  RocketFilled,
  RocketRegular,
  Save20Regular,
  SaveCopyFilled,
  SaveCopyRegular,
  SignOutFilled,
  SignOutRegular,
} from "@fluentui/react-icons";
import PincitesButton from "./PincitesButton";
import { PincitesButtonType } from "./PincitesButtonUtils";
import { Tooltip } from "@fluentui/react-components";

export const AddIcon = bundleIcon(Add16Filled, Add16Regular);
export const ArrowLeftIcon = ArrowLeft24Regular; // Used for back button in navigation
export const ArrowSyncCheckmarkIcon = ArrowSyncCheckmark24Regular;
export const CheckmarkIcon = Checkmark20Regular; // Only used on the banner
export const CircleLineIcon = bundleIcon(CircleLineFilled, CircleLineRegular);
export const CopySelectIcon = bundleIcon(CopySelectFilled, CopySelectRegular);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const DismissIcon = Dismiss24Regular;
export const DownloadIcon = bundleIcon(
  ArrowDownloadFilled,
  ArrowDownloadRegular,
);
export const EditIcon = bundleIcon(EditFilled, EditRegular);
export const ErrorIcon = ErrorCircle20Regular; // Only used on the banner
export const FeedbackIcon = bundleIcon(
  PersonFeedback20Filled,
  PersonFeedback20Regular,
);
export const HistoryIcon = bundleIcon(HistoryFilled, HistoryRegular);
export const LightbulbIcon = bundleIcon(LightbulbFilled, LightbulbRegular);
export const MoreHorizontalIcon = bundleIcon(
  MoreHorizontalFilled,
  MoreHorizontalRegular,
);
export const OpenIcon = OpenRegular;
export const PersonIcon = bundleIcon(PersonFilled, PersonRegular);
export const PlaybookIcon = bundleIcon(BookFilled, BookRegular);
export const PlayCircleIcon = bundleIcon(PlayCircleFilled, PlayCircleRegular);
export const PublishIcon = bundleIcon(RocketFilled, RocketRegular);
export const SaveIcon = Save20Regular;
export const SaveCopyIcon = bundleIcon(SaveCopyFilled, SaveCopyRegular);
export const SignOutIcon = bundleIcon(SignOutFilled, SignOutRegular);
export const UnpublishIcon = bundleIcon(ArrowUndoFilled, ArrowUndoRegular);

export function EditIconButton({
  onClick,
  tooltipText,
  ariaLabel,
}: {
  onClick?: () => void;
  tooltipText?: string;
  ariaLabel?: string;
}): React.JSX.Element {
  return (
    <PincitesButton
      buttonType={PincitesButtonType.SUBTLE}
      icon={<EditIcon />}
      ariaLabel={ariaLabel}
      onClick={onClick}
      tooltipContent={tooltipText}
    />
  );
}

export function DeleteIconButton({
  onClick,
  tooltipText,
  ariaLabel,
}: {
  onClick: () => void;
  tooltipText?: string;
  ariaLabel?: string;
}): React.JSX.Element {
  return (
    <PincitesButton
      buttonType={PincitesButtonType.SUBTLE}
      icon={<DeleteIcon />}
      ariaLabel={ariaLabel ?? "Delete"}
      onClick={onClick}
      tooltipContent={tooltipText}
    />
  );
}

export function MoreHorizontalIconButton({
  onClick,
  index,
}: {
  onClick: () => void;
  index?: number;
}): React.JSX.Element {
  const uniqueId = index ? `${name}-${index}` : `${name}`; // create unique id

  return (
    <Tooltip
      content="More details"
      positioning="above-start"
      withArrow
      relationship="label"
    >
      <MoreHorizontal20Regular tabIndex={0} onClick={onClick} key={uniqueId} />
    </Tooltip>
  );
}
