import { TExtractClauseResponse } from "../types/PlaybookTypes";
import { HTTPMethod } from "./HTTPMethod";
import { HTTPError } from "./HTTPError";
import { captureException } from "../utils/sentry";
import { Maybe } from "../types/Types";
import axios from "axios";
import { getBaseAPIUrl, DEFAULT_TIMEOUT_IN_MS } from "../utils/apiUtils";
import { getUserIDTokenOrThrow } from "../utils/authUtils";
import { IPublicClientApplication } from "@azure/msal-browser";

export async function getExtractedClausesFromContract({
  token,
  file,
}: {
  file: File;
  token: string;
}): Promise<TExtractClauseResponse> {
  const formData = new FormData();
  formData.append("upload", file);

  try {
    const response = await fetch(
      `${getBaseAPIUrl()}/contracts/extract_clauses`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      },
    );

    if (!response.ok) {
      const responseBody = await response.text();
      throw new HTTPError(response.status, responseBody);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export async function copyPlaybookToOrg({
  copyToOrgID,
  msalInstance,
  creatorUserID,
  playbookID,
  onError,
}: {
  onError: (error: Error) => void;
  copyToOrgID: string;
  msalInstance: IPublicClientApplication;
  playbookID: string;
  creatorUserID: string;
}): Promise<boolean> {
  try {
    await makeAuthenticatedRequest({
      msalInstance,
      // The user will always be from Pincites Org
      organizationId: null,
      url: `admin/copy_playbook`,
      httpMethod: HTTPMethod.POST,
      bodyStr: JSON.stringify({
        playbook_id: playbookID,
        destination_org_id: copyToOrgID,
        destination_created_by_user_id: creatorUserID,
      }),
    });
    return true;
  } catch (error) {
    if (error instanceof Error) {
      onError(error);
    }
    return false;
  }
}

export async function createOrUpdateUser({
  idToken,
}: {
  idToken: string;
}): Promise<{ success: boolean; errorMessage?: string }> {
  try {
    // Try to create the user
    const response = await fetch(`${getBaseAPIUrl()}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_token: idToken }),
    });

    if (response.status === 403) {
      // Org has not signed up for Pincites
      return {
        success: false,
      };
    }

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}. Body: ${errorBody}`,
      );
    }
    return { success: true };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function createOrRegisterUserAndOrg({
  idToken,
  accessToken,
}: {
  idToken: string;
  accessToken: string;
}): Promise<{ success: boolean; errorMessage?: string }> {
  try {
    // Try to create the organization (and user)
    let response = await fetch(`${getBaseAPIUrl()}/organizations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_token: idToken, access_token: accessToken }),
    });

    if (response.status === 409) {
      // Org already exists. Create or update the user
      response = await fetch(`${getBaseAPIUrl()}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_token: idToken }),
      });

      if (response.ok) {
        return { success: true };
      }

      if (response.status === 403) {
        const responseJson = await response.json();
        if (responseJson.errors?.at(0)?.code === "organization_disabled") {
          return { success: false };
        }
      }
    }

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}. Body: ${errorBody}`,
      );
    }
    return { success: true };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function fetchUserProfilePic({
  accessToken,
}: {
  accessToken: string;
}): Promise<void> {
  try {
    const response = await fetch(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    if (response.ok) {
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer]);

      // Convert the blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        localStorage.setItem("profilePic", base64data as string);
      };
    } else if (response.status === 404) {
      // No profile picture
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

// Runs REST requests, logs errors, and returns a response
async function makeAuthenticatedRequest({
  msalInstance,
  organizationId,
  url,
  httpMethod,
  bodyStr,
  additionalHeaders = {},
}: {
  msalInstance: IPublicClientApplication;
  organizationId: Maybe<string>;
  httpMethod: HTTPMethod;
  url: string;
  bodyStr?: string;
  additionalHeaders?: Record<string, string>;
}): Promise<any> {
  try {
    // Fetch a token
    const token = await getUserIDTokenOrThrow(msalInstance);

    // Set the organization header
    if (organizationId) {
      additionalHeaders["Pincites-Organization"] = organizationId;
    }

    // Make the request
    const response = await axios({
      method: httpMethod,
      url: `${getBaseAPIUrl()}/${url}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        ...additionalHeaders,
      },
      data: bodyStr,
      timeout: DEFAULT_TIMEOUT_IN_MS,
    });

    return response.data || null;
  } catch (error) {
    // Used for typescript
    if (!(error instanceof Error)) {
      return;
    }
    captureException({ error });
    console.error("Encountered an error while running the API:", error);
    throw error;
  }
}

export async function getFeedbackResponse(
  feedbackSummary: string,
  feedbackDetails: string,
  msalInstance: IPublicClientApplication,
): Promise<void> {
  try {
    await makeAuthenticatedRequest({
      msalInstance,
      organizationId: null, // Always give feedback from default org
      url: "feedback",
      httpMethod: HTTPMethod.POST,
      bodyStr: JSON.stringify({
        subject: feedbackSummary,
        message: feedbackDetails,
      }),
    });
  } catch (error) {
    throw error;
  }
}
