import { TokenClaims } from "@azure/msal-common";
import { IPublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import { DecodedMicrosoftToken } from "../types/AuthTypes";
import { Maybe } from "../types/Types";

export const PINCITES_TENANT_ID = "258d71dc-b90b-4f45-88be-a36edd3878b9";
export const REDIRECT_KEY = "redirectToAfterLogin";

export class NoActiveAccountError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NoActiveAccountError";
  }
}

export class ActiveAccountChangedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ActiveAccountChangedError";
  }
}

export async function getUserIDTokenOrThrow(
  msalInstance: IPublicClientApplication,
): Promise<string> {
  if (!msalInstance.getActiveAccount()) {
    throw new NoActiveAccountError("No active account");
  }

  // Note: since this is a _silent_ request the redirectURI
  // is never seen by the user. We use /blank.html to avoid
  // the hash in the redirect being cleared by our standard
  // redirect logic.
  let response = await msalInstance.acquireTokenSilent({
    scopes: ["User.Read"],
    redirectUri: "/blank.html",
  });

  // If the token is expired, force a refresh
  if (isTokenExpired(response.idTokenClaims)) {
    response = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read"],
      redirectUri: "/blank.html",
      forceRefresh: true,
    });
  }
  return response.idToken;
}

export function isTokenExpired(token: TokenClaims): boolean {
  if (!token.exp) {
    return false;
  }

  return new Date(token.exp * 1000) < new Date();
}

export function isPincitesAdmin(msalInstance: IPublicClientApplication) {
  const account = msalInstance.getActiveAccount();
  const tenant = account?.tenantId;
  // if tenant is null, then we don't have a token, so we can't check if the user is an admin
  if (!tenant) {
    return false;
  }
  // If the user is our friendly pen tester and we're in staging, they are an admin
  if (
    account?.localAccountId === "3e56ab33-d18d-48bf-ba27-22c8dc69c70a" &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
  ) {
    return true;
  }

  // if tenant is Pincites, then the user is an admin
  return tenant === PINCITES_TENANT_ID;
}

/**
 * Decodes a Microsoft ID token and returns the decoded token if the claims are valid.
 *
 * Does not validate the signature of the token.
 */
export function decodeMicrosoftIdToken(
  idToken: string,
): Maybe<DecodedMicrosoftToken> {
  // Decode the token
  const decodedToken: DecodedMicrosoftToken = jwtDecode(idToken);

  // Check the claims
  if (
    typeof decodedToken.name !== "string" ||
    typeof decodedToken.email !== "string"
  ) {
    return null;
  }

  return decodedToken;
}
