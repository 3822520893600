"use client";
import React, { useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { PincitesBannerType } from "./library/PincitesBannerType.ts";
import { Margin } from "./library/Margin.ts";
import PincitesBanner from "./library/PincitesBanner.tsx";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { createOrUpdateUser } from "../api/PlaybookAPI.ts";
import {
  NoActiveAccountError,
  getUserIDTokenOrThrow,
} from "../utils/authUtils.ts";
import PincitesFlexbox, {
  PincitesFlexboxAlignItems,
  PincitesFlexboxDirection,
  PincitesFlexboxGap,
  PincitesFlexboxJustifyContent,
} from "./library/PincitesFlexbox.tsx";
import {
  Divider,
  Link,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import PincitesBadge, {
  PincitesBadgeAppearance,
  PincitesBadgeColor,
} from "./library/PincitesBadge.tsx";
import {
  PincitesShadow,
  PincitesColor,
  PincitesBackgroundImage,
} from "./library/PincitesTheme.tsx";
import PincitesButton from "./library/PincitesButton.tsx";
import { PincitesButtonType } from "./library/PincitesButtonUtils.tsx";
import {
  PincitesSpinner,
  PincitesSpinnerSize,
} from "./library/PincitesSpinner.tsx";
import { PincitesLogo } from "./library/images/PincitesLogo.tsx";
import { MicrosoftLogo } from "./library/images/MicrosoftLogo.tsx";
import PincitesText, {
  PincitesFontType,
  PincitesTextType,
} from "./library/PincitesText.tsx";

const useStyles = makeStyles({
  container: {
    zIndex: -1,
    backgroundImage: PincitesBackgroundImage.WELCOME_PAGE,
    pointerEvents: "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  landingPageContent: {
    pointerEvents: "auto",
    backgroundColor: PincitesColor.NEUTRAL_BACKGROUND,
    ...shorthands.borderRadius("8px"),
    ...shorthands.borderBottom(`4px solid ${PincitesColor.BRAND_BLUE}`),
    ...shorthands.paddingBlock("2rem"),
    boxShadow: PincitesShadow.SHADOW16,
  },
  signInDivider: {
    width: "80%",
  },
  logoImage: {
    width: "80%",
    maxWidth: "300px",
    ...shorthands.paddingBlock("3rem"),
  },
  demoLink: {
    color: PincitesColor.BRAND_BLUE,
    fontWeight: 400,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  cardContent: {
    ...shorthands.paddingInline("0.5rem"),
    "& > *": {
      whiteSpace: "nowrap",
    },
    "& > button": {
      minWidth: "60%",
    },
  },
  notSignedUpLink: {
    color: "inherit",
    display: "inline",
    ...shorthands.textDecoration("underline"),
    ":hover": {
      color: PincitesColor.WHITE_ALWAYS,
    },
  },
});

export default function LandingPage(): React.JSX.Element {
  const router = useRouter();
  const { instance: msalInstance, accounts } = useMsal();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const searchParams = useSearchParams();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const styles = useStyles();
  const orgNotRegistered = searchParams.get("org_not_registered") != null;

  useEffect(() => {
    async function fetchToken() {
      // If the org isn't registered, show the error banner and don't redirect
      if (orgNotRegistered) {
        setShowErrorBanner(orgNotRegistered);
        setIsCheckingAuth(false);
        return;
      }

      // Otherwise, check if we have an active account
      if (!msalInstance.getActiveAccount()) {
        if (accounts.length == 1) {
          msalInstance.setActiveAccount(accounts[0]);
        } else {
          setIsCheckingAuth(false);
          return;
        }
      }

      // If we do, try to get a token silently
      getUserIDTokenOrThrow(msalInstance).then(
        async (token: string) => {
          // If we get a token, check if it's valid for Pincites
          try {
            const createUserResponse = await createOrUpdateUser({
              idToken: token,
            });
            if (createUserResponse.success === false) {
              router.replace("/?org_not_registered=true");
              return;
            }

            // If we get here we're authenticated and ready to go!
            router.replace("/playbooks");
          } catch (error) {
            setIsCheckingAuth(false);
            throw error;
          }
        },
        (error) => {
          setIsCheckingAuth(false);
          if (
            error instanceof InteractionRequiredAuthError ||
            error instanceof NoActiveAccountError
          ) {
            // Ignore interaction required errors - we're already on the log in page
            return;
          }
          throw error;
        },
      );
    }

    fetchToken();
  }, [msalInstance, accounts, router, orgNotRegistered]);

  const redirectToMsalLogin = async () => {
    msalInstance.loginRedirect({
      scopes: ["User.Read"],
      redirectUri: "/redirect",
      prompt: orgNotRegistered ? "select_account" : undefined,
    });
  };

  if (isCheckingAuth) {
    return (
      <PincitesSpinner
        id="checking-auth-for-login"
        size={PincitesSpinnerSize.LARGE}
        pageLoadSpinner={true}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
        <PincitesFlexbox
          direction={PincitesFlexboxDirection.VERTICAL}
          justifyContent={PincitesFlexboxJustifyContent.CENTER}
          gap={PincitesFlexboxGap.GAP_8}
          alignItems={PincitesFlexboxAlignItems.CENTER}
          customClassName={styles.landingPageContent}
          id="landing-page-content"
        >
          <PincitesBanner
            isShown={showErrorBanner}
            content={
              <OrganizationNotRegisteredBannerContent
                linkStyle={styles.notSignedUpLink}
              />
            }
            bannerType={PincitesBannerType.Error}
            isTemporary={false}
          />
          <PincitesFlexbox
            direction={PincitesFlexboxDirection.VERTICAL}
            justifyContent={PincitesFlexboxJustifyContent.CENTER}
            gap={PincitesFlexboxGap.GAP_12}
            alignItems={PincitesFlexboxAlignItems.CENTER}
            margin={[Margin.MARGIN_BOTTOM_16]}
            customClassName={styles.cardContent}
          >
            <Link
              className={styles.demoLink}
              href="https://calendly.com/pincites/demo"
              target="_blank"
              rel="noopener noreferrer"
              id="request-demo-link"
            >
              <PincitesBadge
                appearance={PincitesBadgeAppearance.FILLED}
                color={PincitesBadgeColor.BRAND}
                text="NEW HERE?"
              />
              Request demo
            </Link>
            <PincitesLogo
              className={styles.logoImage}
              width={"100%"}
              onClick={() => {
                router.push("https://pincites.com/");
              }}
            />
            <Divider className={styles.signInDivider}>Sign in with</Divider>
            <PincitesButton
              buttonType={PincitesButtonType.SECONDARY}
              icon={<MicrosoftLogo width={150} height={150} />}
              onClick={redirectToMsalLogin}
              label="Microsoft"
            />
          </PincitesFlexbox>
        </PincitesFlexbox>
      </div>
    </div>
  );
}

function OrganizationNotRegisteredBannerContent({
  linkStyle,
}: {
  linkStyle: string;
}) {
  return (
    <PincitesText
      textType={PincitesTextType.BODY1}
      fontType={PincitesFontType.WHITE}
    >
      Your organization is not signed up.{" "}
      <Link href="https://calendly.com/pincites/demo" className={linkStyle}>
        Schedule a demo
      </Link>{" "}
      to get started.
    </PincitesText>
  );
}
